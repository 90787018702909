import React, { useState } from "react";
import { Card } from "../styles/propertyStyles";
import { useNavigate } from "react-router-dom";
import styles from "../styles/property.module.css";
import DataTable from "components/parts/DataTable";
import { Box, Button, Modal, Typography } from "@mui/material";
import { izypromanService } from "apis";

function getDate(params) {
  return `${new Date(params.row.createdAt).toLocaleDateString() || `-`}`
}

const DeleteConfirmationModal = ({ open, onClose, onConfirm, userId }) => (
  <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        textAlign: "center",
      }}
    >
      <Typography variant="h6" component="h2" mb={2}>
        Confirm Deletion
      </Typography>
      <Typography mb={4}>
        Are you sure you want to delete this record?
      </Typography>
      <div style={{ display: "flex", gap: '2rem', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => onConfirm(userId)}
        >
          Delete
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Box>
  </Modal>
);

const ActiveRenewals = ({ applicants }) => {
  const navigate = useNavigate();

  const handleView = (liveizy_id, apartment_id) => {
    navigate(`/property/monthly-rent/renewals/${liveizy_id}/${apartment_id}`);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const deleteUser = (id) => {
    izypromanService()
      .delete(`/renewal-application/${id}`)
      .then((response) => {
        const { data } = response.data;
        alert(data?.message);
        setModalOpen(false);
      })
      .catch((error) => {
        alert(error);
        setModalOpen(false);
      });
  };

  const openDeleteModal = (id) => {
    setUserIdToDelete(id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setUserIdToDelete(null);
  };

  const usersTableColumns = [
    { field: '_id', headerName: "User Name", width: 300, headerClassName: 'bold-header', },
    { field: 'apartment_id', headerName: "Apartment ID", width: 300, headerClassName: 'bold-header' },
    { field: "lid", headerName: "Tenant LID", width: 300, headerClassName: 'bold-header' },
    { field: 'createdAt', headerName: "Date Applied", width: 300, valueGetter: getDate, headerClassName: 'bold-header' },
    {
      field: "view",
      headerName: "",
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', gap: '2rem' }}>
            <Button
              color="primary"
              sx={{ background: "#10609F", fontSize: "10px", color: "white" }}
              onClick={() => handleView(params?.row.lid, params?.row.apartment_id)}
            >
              View
            </Button>
            <Button
              color="primary"
              sx={{ background: "#cf3917", fontSize: "10px", color: "white" }}
              onClick={() => openDeleteModal(params?.row._id)}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DeleteConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={deleteUser}
        userId={userIdToDelete}
      />
      <Card>
        <p className={styles.awaitingListing}> Accounts with monthly rent </p>
        <h1 className={styles.awaitingListingCount}>{applicants.length}</h1>
      </Card>

      <div style={{ margin: '50px 0' }}>
        <DataTable
          autoHeight
          rows={applicants}
          columns={usersTableColumns}
          getRowId={(row) => row._id}
        />
      </div>
    </>
  );
};

export default ActiveRenewals;
