import { Button, styled } from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/images/liveizy-logo.png";
import TextandLogoField from "../../components/parts/TextandLogoField";
import { TiUser } from "react-icons/ti";
import { BsFillKeyFill } from "react-icons/bs";
import { CheckBox } from "@mui/icons-material";

import { authService } from "../../apis";
import { useAppContext } from "../../context/context";
import AlertBar from "../../components/popups/AlertBar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LOGIN_USER_FAIL,
  SERVER_ERROR,
  LOGIN_USER_SUCCESS,
} from "../../context/actions";
import { ROUTES } from "../../constants/routes";
import { useEffect } from "react";
import { checkLoggedIn } from "../../utils/auth";

const Container = styled("div")`
  display: flex;
`;
const QuoteContainer = styled("div")`
  height: 100vh;
  flex: 2.5;
  background: linear-gradient(
    70.17deg,
    #052137 7.39%,
    rgba(13, 46, 72, 0.88) 56.82%,
    rgba(5, 33, 55, 0.78) 99.37%,
    rgba(7, 36, 59, 0.827247) 99.66%
  );
`;

const Quote = styled("div")`
  font-weight: 500;
  font-size: 24px;
  color: white;
  width: 70%;
`;

const FormContainer = styled("div")`
  flex: 4;
  height: 100vh;
  background: white;
`;

const FormDiv = styled("div")`
  width: 100%;
`;

const Logo = styled("div")`
  max-width: 150px;
  max-height: 30px;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
  }
`;

const Form = styled("form")`
  margin-top: 20px;
  gap: 20px;
`;

const RememberPswrd = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
`;

const TextField = styled("div")`
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
`;

const Login = () => {
  const [lid, setLid] = useState("");
  const [password, setPassword] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || ROUTES.dashboard; // this will get a prev path user tried to access or use "/" as default

  const { showAlert, displayAlert, dispatch } = useAppContext();
  const isLoggedIn = checkLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(from, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    if (!lid || !password) {
      return displayAlert();
    }
    const formValues = {
      liveizy_id: lid,
      password: password,
    };
    try {
      const response = await authService().post(
        "/admin/auth/login",
        formValues
      );

      const user = response.data.data.user;
      const token = response.data.data.token;

      // set state to user data
      dispatch({ type: LOGIN_USER_SUCCESS, payload: { user, token } });

      // store data in local storage
      localStorage.setItem("Authorization", token);
      localStorage.setItem("APP_USER_DETAIL", JSON.stringify(user));

      // Navigate user to dashboard
      navigate(from, { replace: true });
    } catch (error) {
      console.log(error);
      if (error.status === 400) {
        dispatch({
          type: LOGIN_USER_FAIL,
          payload: { msg: error.response.data.message },
        });
      } else {
        dispatch({ type: SERVER_ERROR });
      }
    }
  };

  return (
    <Container>
      <QuoteContainer className="position-center">
        <Quote>
          “If you want to go quickly, go alone. If you want to go far, go
          together.”
          <br></br>
          <br></br>– African Proverb
        </Quote>
      </QuoteContainer>
      <FormContainer className="position-column-center">
        <FormDiv className="position-column-center">
          <Logo>
            <img src={logo} alt="Liveizy Logo" />
          </Logo>
          <h2 style={{ fontWeight: "bolder" }}>Login To Your Account</h2>
          <p
            style={{
              fontWeight: 500,
              marginTop: "0px",
            }}
          >
            Input your Login details to access your account
          </p>

          {showAlert && <AlertBar />}
          <Form className="position-column-center" onSubmit={submitForm}>
            <TextField>
              <TextandLogoField
                fieldType="text"
                logo={<TiUser />}
                placeholder="LID"
                onChange={(e) => setLid(e.target.value)}
                value={lid}
              />
            </TextField>
            <TextField>
              <TextandLogoField
                fieldType="password"
                logo={<BsFillKeyFill />}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </TextField>
            <RememberPswrd>
              <CheckBox size="small" sx={{ fontSize: "16px" }} />
              <small>Remember Password</small>
            </RememberPswrd>

            <Button
              sx={{ backgroundColor: "#10609F" }}
              variant="contained"
              type="submit"
            >
              Sign In
            </Button>
            <small style={{ color: "red", fontWeight: "bold" }}>
              Forgot Password?
            </small>
          </Form>
        </FormDiv>
      </FormContainer>
    </Container>
  );
};

export default Login;
