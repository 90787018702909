// /* eslint-disable no-unused-vars */
import { Button, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextandLogoField from "../../components/parts/TextandLogoField";
import { useAppContext } from "../../context/context";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { BiSearch } from "react-icons/bi";
import DataTable from "../../components/parts/DataTable";
import { authService } from "../../apis";
import { usersTableColumns } from "./utils";

const Container = styled("div")`
  width: 100%;
`;
const TopSection = styled("section")`
  display: flex;
  align-self: center;
  justify-content: flex-start;
  gap: 50px;
  margin-bottom: 40px;
  width: 100%;
`;

const SearchBar = styled("div")`
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 4px solid rgba(16, 96, 159, 0.4);
  border-radius: 4px;
  height: 60px;
  width: 450px;
`;

const Users = () => {
  const { setNavState, userTablePage, setUserTablePage } = useAppContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [query, setQuery] = useState("");
  const [tableData, setTableData] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: userTablePage || 1,
    pageSize: 10,
  });
  const [filteredTableData, setFilteredTableData] = useState([]);

  useEffect(() => {
    setNavState("USERS");
  }, [setNavState]);

  useEffect(() => {
    const fetchData = () => {
      setTableData((prev) => ({ ...prev, isLoading: true }));

      authService()
        .get("/admin/users", { params: { page: tableData.page } })
        .then((res) => {
          const data = res.data.data;
          const total = res.data.meta.total;
          const pageSize = res.data.meta.per_page;
          const page = res.data.meta.current_page;

          setTableData((prev) => ({
            ...prev,
            data,
            total,
            page,
            pageSize,
            isLoading: false,
          }));
          setFilteredTableData(data); // Set initial filtered data
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, [tableData.pageSize, tableData.page]);

  useEffect(() => {
    if (query.length === 8) {
      setTableData((prev) => ({ ...prev, isLoading: true }));

      authService()
        .get(`/admin/users/${query}`)
        .then((res) => {
          setTableData((prev) => ({ ...prev, isLoading: false }));
          setFilteredTableData([res.data.data]); // Set filtered data
        })
        .catch((error) => {
          setTableData((prev) => ({ ...prev, isLoading: false }));
          if (error.response.status === 404) {
            return alert("User with Liveizy ID not Found");
          }
          alert(error.response.statusText);
        });
    }
  }, [query]);

  useEffect(() => {
    setUserTablePage(tableData.page);
  }, [setUserTablePage, tableData.page]);

  useEffect(() => {
    const filteredData = tableData.data.filter((user) => {
      const matchesQuery = searchQuery
        ? `${user.first_name} ${user.last_name} ${user.email}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
        : true;

      return matchesQuery;
    });

    setFilteredTableData(filteredData); // Set filtered data without overwriting the original data
  }, [searchQuery, tableData.data]);

  return (
    <Container>
      <TopSection>
        <SearchBar>
          <TextandLogoField
            logo={<HiOutlinePencilAlt />}
            placeholder="Search users by Liveizy ID"
            fieldType="text"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
          <Button
            variant="contained"
            startIcon={<BiSearch />}
            sx={{ width: "200px", height: "100%", background: "#10609F" }}
          >
            Search
          </Button>
        </SearchBar>
        <SearchBar>
          <TextandLogoField
            logo={<HiOutlinePencilAlt />}
            placeholder="Search users by Name or Email"
            fieldType="text"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </SearchBar>
      </TopSection>

      <div>
        <DataTable
          autoHeight
          rows={filteredTableData} // Use filtered data
          columns={usersTableColumns}
          pageSize={tableData.pageSize}
          rowsPerPageOptions={[50]}
          rowCount={tableData.total}
          loading={tableData.isLoading}
          pagination
          page={tableData.page - 1}
          paginationMode="server"
          onPageChange={(newPage) =>
            setTableData((prevState) => ({ ...prevState, page: newPage + 1 }))
          }
          onPageSizeChange={(newPageSize) =>
            setTableData((prevState) => ({
              ...prevState,
              pageSize: newPageSize,
            }))
          }
        />
      </div>
    </Container>
  );
};


export default Users;
